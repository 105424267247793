import { Button } from '@daily/shared/components/Button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from '@daily/shared/components/Card';
import { Checkbox } from '@daily/shared/components/Checkbox';
import { Form } from '@daily/shared/components/Form';
import { Input } from '@daily/shared/components/Input';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { Toggle } from '@daily/shared/components/Toggle';
import { ThemeProvider } from '@daily/shared/contexts/Theme';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { Layout } from '/components/App/Layout';

export default function IndexPage() {
  const [roomName, setRoomName] = useState('');
  const [domainName, setDomainName] = useState('');
  const [username, setUsername] = useState('');
  const [token, setToken] = useState('');
  const [debug, setDebug] = useState(false);
  const [audio, setAudio] = useState(true);
  const [grid, setGrid] = useState(false);
  const [pageSize, setPageSize] = useState<number>();
  const [maxCamSubs, setMaxCamSubs] = useState<number>();
  const [customHost, setCustomHost] = useState('');
  const [bypassRegionDetection, setBypassRegionDetection] = useState(false);
  const { push } = useRouter();

  const handleSubmit = (ev) => {
    ev.preventDefault();
    const params = new URLSearchParams({ domain: domainName });
    if (token) params.set('t', token);
    if (!audio) params.set('audio', 'false');
    if (debug) params.set('debug', '1');
    if (customHost) params.set('customHost', customHost);
    if (username) params.set('name', username);
    if (grid) params.set('v', 'grid');
    if (pageSize) params.set('ps', pageSize.toString());
    if (bypassRegionDetection) params.set('bypassRegionDetection', 'true');
    if (maxCamSubs) params.set('max_cam_subs', maxCamSubs.toString());
    push(`/${roomName}?${params.toString()}`);
  };

  useEffect(() => {
    // allows us to use a url to quickly test the app joining without having to manuall enter the fields
    // https://khk-local.wss.daily.local:3001/?domain=flown-preview&room=drop-in&username=mike&token=CCC
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const username = urlParams.get('name');
    const domain = urlParams.get('domain');
    const roomName = urlParams.get('room');

    if (token) {
      setToken(token);
    }
    if (username) {
      setUsername(username);
    }
    if (domain) {
      setDomainName(domain);
    }
    if (roomName) {
      setRoomName(roomName);
    }
  }, [setDomainName, setRoomName, setUsername, setToken]);

  return (
    <ThemeProvider>
      <Layout>
        <div className="container">
          <Form onSubmit={handleSubmit}>
            <Stack width="300px">
              <Card shadow="strong">
                <CardHeader>
                  <Stack gap={0}>
                    <Text variant="largestrong">Join a call</Text>
                    <Text variant="small">
                      This view is only relevant for Vercel previews.
                    </Text>
                  </Stack>
                </CardHeader>
                <CardContent>
                  <Stack>
                    <Text El="label" htmlFor="room" variant="strong">
                      Room name
                    </Text>
                    <Input
                      autoComplete="on"
                      id="room"
                      name="room"
                      value={roomName}
                      onChange={(ev) => setRoomName(ev.target.value)}
                      required
                    />
                    <Text El="label" htmlFor="room" variant="strong">
                      Domain name
                    </Text>
                    <Input
                      autoComplete="on"
                      id="domain"
                      name="domain"
                      value={domainName}
                      onChange={(ev) => setDomainName(ev.target.value)}
                      required
                    />
                    <Text El="label" htmlFor="token" variant="strong">
                      Meeting token
                    </Text>
                    <Input
                      id="token"
                      name="token"
                      value={token}
                      onChange={(ev) => setToken(ev.target.value)}
                    />
                    <Text El="label" htmlFor="username" variant="strong">
                      Username
                    </Text>
                    <Input
                      autoComplete="nickname"
                      id="username"
                      name="username"
                      value={username}
                      onChange={(ev) => setUsername(ev.target.value)}
                    />
                    <Toggle
                      closedLabel="Advanced parameters"
                      id="advanced-params"
                      openLabel="Advanced parameters"
                    >
                      <Stack>
                        <Text El="label" htmlFor="customHost" variant="strong">
                          Custom host
                        </Text>
                        <Input
                          id="customHost"
                          name="customHost"
                          value={customHost}
                          onChange={(ev) => setCustomHost(ev.target.value)}
                        />
                        <Checkbox
                          id="debug"
                          name="debug"
                          label="Enable debug parameter"
                          checked={debug}
                          onChange={(ev) => setDebug(ev.target.checked)}
                        />
                        <Checkbox
                          id="audio"
                          name="audio"
                          label="Disable audio"
                          checked={!audio}
                          onChange={(ev) => setAudio(!ev.target.checked)}
                        />
                        <Checkbox
                          id="grid"
                          name="grid"
                          label="Start in Grid view"
                          checked={grid}
                          onChange={(ev) => setGrid(ev.target.checked)}
                        />
                        <Text El="label" htmlFor="page_size" variant="strong">
                          Grid: Page size
                        </Text>
                        <Input
                          id="page_size"
                          name="page_size"
                          min={1}
                          max={49}
                          step={1}
                          type="number"
                          error={pageSize < 1 || pageSize > 49}
                          value={pageSize}
                          onChange={(ev) => {
                            setPageSize(parseInt(ev.target.value, 10));
                          }}
                        />
                        {pageSize < 1 || pageSize > 49 ? (
                          <Text color="error" variant="small">
                            Must be between 1 and 49.
                          </Text>
                        ) : null}
                        <Text
                          El="label"
                          htmlFor="max_cam_subs"
                          variant="strong"
                        >
                          Maximum cam subscriptions
                        </Text>
                        <Input
                          id="max_cam_subs"
                          name="max_cam_subs"
                          min={1}
                          max={49}
                          step={1}
                          type="number"
                          error={maxCamSubs < 1 || maxCamSubs > 49}
                          value={maxCamSubs}
                          onChange={(ev) => {
                            setMaxCamSubs(parseInt(ev.target.value, 10));
                          }}
                        />
                        {maxCamSubs < 1 || maxCamSubs > 49 ? (
                          <Text color="error" variant="small">
                            Must be between 1 and 49.
                          </Text>
                        ) : null}
                        <Checkbox
                          id="bypassRegionDetection"
                          name="bypassRegionDetection"
                          label="Bypass region detection"
                          checked={bypassRegionDetection}
                          onChange={(ev) =>
                            setBypassRegionDetection(ev.target.checked)
                          }
                        />
                      </Stack>
                    </Toggle>
                  </Stack>
                </CardContent>
                <CardFooter>
                  <Stack>
                    <Button
                      disabled={!roomName || !domainName}
                      fullWidth
                      variant="primary"
                      type="submit"
                    >
                      Start call
                    </Button>
                    {!roomName || !domainName ? (
                      <Text color="muted" variant="small">
                        Please provide at least a room and domain name.
                      </Text>
                    ) : null}
                  </Stack>
                </CardFooter>
              </Card>
            </Stack>
          </Form>
        </div>
      </Layout>
      <style jsx>{`
        .container {
          align-items: center;
          display: grid;
          height: 100%;
          justify-content: center;
          overflow: auto;
          padding: 10px;
          width: 100%;
        }
      `}</style>
    </ThemeProvider>
  );
}

import classnames from 'classnames';
import React, { useState } from 'react';

import { CaretDownIcon } from '../Icons/CaretDownIcon';
import { Stack } from '../Stack';
import { Text } from '../Text';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  closedLabel: string;
  defaultOpen?: boolean;
  disabled?: boolean;
  id: string;
  openLabel: string;
  subline?: string;
  variant?: 'large' | 'small';
}

interface VariantStyle {
  fontSize: React.ComponentProps<typeof Text>['variant'];
  gap: number;
  margin: number;
}

const variantStyles: Record<Props['variant'], VariantStyle> = {
  large: {
    fontSize: 'large',
    gap: 8,
    margin: 24,
  },
  small: {
    fontSize: 'strong',
    gap: 4,
    margin: 8,
  },
};

export const Toggle: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  closedLabel,
  defaultOpen = false,
  disabled = false,
  id,
  openLabel,
  subline,
  variant = 'large',
  ...props
}) => {
  const [open, setOpen] = useState(defaultOpen);
  const label = open ? openLabel : closedLabel;

  const toggle = () => setOpen(!open);
  const handleClick = () => {
    if (disabled) return;
    toggle();
  };
  const handleKeyPress = (ev: React.KeyboardEvent) => {
    if (!disabled && (ev.key === 'Enter' || ev.key === ' ')) {
      toggle();
    }
  };

  return (
    <>
      <div
        className={classnames('toggle', className, { disabled })}
        role="button"
        aria-controls={id}
        aria-disabled={disabled}
        aria-expanded={open}
        onClick={handleClick}
        onKeyPress={handleKeyPress}
        tabIndex={disabled ? -1 : 0}
        {...props}
      >
        {label && (
          <Stack gap={0}>
            <div className="toggle-inner">
              <Text El="strong" variant={variantStyles[variant].fontSize}>
                {label}
              </Text>
              <span className="iconContainer">
                <span className="icon">
                  <CaretDownIcon size={16} />
                </span>
              </span>
            </div>
            {subline && <Text color="muted">{subline}</Text>}
          </Stack>
        )}
      </div>
      <div id={id}>{children}</div>
      <style jsx>{`
        .toggle {
          align-items: center;
          border-radius: 4px;
          box-shadow: 0 0 0 0 transparent;
          cursor: pointer;
          display: inline-flex;
          padding: 2px;
          transition: box-shadow 200ms ease;
          -webkit-tap-highlight-color: transparent;
        }
        .toggle.disabled {
          cursor: default;
          opacity: 0.6;
        }
        .toggle.disabled:focus {
          outline: none;
        }
        .toggle-inner {
          display: flex;
          gap: ${variantStyles[variant].gap}px;
        }
        .iconContainer {
          background-color: transparent;
          border-radius: 4px;
          display: inline-flex;
          outline: 1px solid transparent;
          transition: background 200ms ease, border 200ms ease;
        }
        .icon {
          display: inline-flex;
          transition: transform 200ms ease;
        }
        .toggle:not(.disabled):hover .iconContainer {
          background-color: var(--toggle-icon-hover-bg);
          outline-color: var(--toggle-icon-hover-border);
        }
        .toggle:not(.disabled):focus {
          box-shadow: 0 0 0 1px var(--focus-border),
            0 0 0 3px var(--focus-shadow);
          outline: none;
        }
        .toggle[aria-expanded='true'] .icon {
          transform: rotate(-180deg);
        }
        .toggle[aria-expanded='true'] + div {
          display: block;
          margin-top: ${variantStyles[variant].margin}px;
        }
        .toggle[aria-expanded='false'] + div {
          display: none;
        }
      `}</style>
    </>
  );
};
